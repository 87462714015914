.table-header {
  background-color: white;
  text-align: center;
  margin-left: 30px !important;
  padding: 2px 8px;
  border-top-right-radius: 9px !important;
  border-top-left-radius: 9px !important;
}

.header {
  margin-left: 30px !important;
}

.no-item-price-row:not(.MuiDataGrid-row--editing) {
  background-color: rgba(var(--yellowColorRGB), .85);
}

.no-item-price-row:hover:not(.MuiDataGrid-row--editing) {
  filter: brightness(85%);
  background-color: rgba(var(--yellowColorRGB), .85) !important; 
}

.cellLeftAlign {
  justify-content: flex-start !important;
}