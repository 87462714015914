/* MUI Overrides */

/* Data Grid Override */
.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
  overflow: visible !important;
  -webkit-font-smoothing: antialiased;
}

/* .MuiDataGrid-root .MuiDataGrid-cell {
  white-space: normal !important;
  word-wrap: break-word !important;
} */

.MuiDrawer-paperAnchorDockedLeft {
  border-right: 0px !important;
}

/* Full Calendar Override */
.fc-day-today {
  background-color: #c8feff !important;
}

.fc-scroller {
  height: auto !important;
}

.fc-head .fc-widget-header {
  margin-right: 0 !important;
}

.fc-scroller {
  overflow: visible !important;
}

.fc .fc-scroller-liquid-absolute {
  position: inherit;
}

.css-160unip {
  padding-top: 0px !important;
  overflow: visible !important;
}

/* Zoom Chat Overrides */
.livesdk__placement-inner.bottom-left {
  bottom: 40px !important;
}
