/* .top {
  margin-top: 100px !important;
} */

.adminCard {
  /* white */
  background: var(--whiteColor);

  /* sha */
  box-shadow: var(--tealBoxShadow) !important;
  border-radius: var(--borderRadius) !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--tealColor);
  min-height: 80px;
  min-width: 100%;
  max-height: 80px;
  overflow:hidden;
}

.adminCard:hover {
  /* white */
  background: var(--tealColor);
  /* sha */
  color: var(--whiteColor);
}

.cardActions:not(:last-child) {
  border-right: 3px solid var(--tealColor);
}

.cardActionsRoot {
  /* margin-top: 0 !important;
  padding-top: 0 !important; */
  min-height: 80px;
} 


.cardActions {
  text-align: center;
  margin: 0 !important;
}

.cardActions {
  color: var(--tealColor);
  text-decoration: none;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  
  letter-spacing: 0.005em;
}

.adminCardContent {
  text-align: center;
  color: var(--tealColor);
  margin: 8px;
  margin-bottom: 0;
  padding-bottom: 0; 
   height: 80px;

}

.adminCard:hover .adminCardContent {
  color: var(--whiteColor);
  transition: 0.5s ease-in-out;
}

.adminCard:hover .cardActions {
  color: var(--whiteColor);
  transition: 0.5s ease-in-out;
}

.adminCard:hover .cardActions:not(:last-child) {
  border-right: 3px solid var(--whiteColor);
  transition: 0.5s ease-in-out;
}

.adminCard.active {
  background-color: var(--tealColor);
}

.adminCard.active .cardActions {
  color: var(--whiteColor);
  transition: 0.5s ease-in-out;
}

.adminCard.active .adminCardContent {
  color: var(--whiteColor);
  transition: 0.5s ease-in-out;
}

.adminCard .cardActions.active {
  color: var(--tealColor);
  background-color: var(--whiteColor);
  transition: 0.5s ease-in-out;
  border-radius: 5px;
}

.adminCard .cardActions.active a {
  color: var(--tealColor);
}

.adminCard.active .cardActions:not(:last-child) {
  border-right: none !important;
  transition: 0.5s ease-in-out;
}

.adminCardTitle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.adminCardIcon {
  font-size: 42px !important;
  margin-right: 8px;
}

.adminTableBody {
  display: "flex";
  width: 100%;
  padding-top: 20px;
}

.text-editor {
  border: 1px solid #80808070;
  border-radius: 4px;
  height: 200px;
  padding: 10px;
}

.adminContainer {
  margin-top: 10px;
  padding: 5px;
}
